@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

a, button {
  transition: opacity 300ms;
}
a:hover,
button:hover {
  opacity: 75%;
}
